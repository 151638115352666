/* eslint-disable max-len */
import React from 'react'
import {
  Container,
  Grid,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import { formatDate } from 'utils/helpers';
import { COURSE_TYPES_ENUM } from 'utils/constants';
import getType from 'components/TypeIcon';
import { EnrollCoursesResponse } from 'services/interfaces';
import { ILearningObjectDetail } from '../shared';

export default function CertificationCardDetail(
  {
    learningObjectDetail, onChecked, selected, enrolledStatus,
  }:
  { learningObjectDetail: ILearningObjectDetail,
    onChecked: Function,
    selected: boolean,
    enrolledStatus: EnrollCoursesResponse | undefined
  },
) {
  const ilt = learningObjectDetail.learningObjectTypeId === COURSE_TYPES_ENUM.CLASSROOM;
  const online = learningObjectDetail.learningObjectTypeId === COURSE_TYPES_ENUM.ONLINE;

  const handleChange = (event: { target: { id: any; checked: any; }; }) => {
    onChecked(event.target.id, event.target.checked)
  };

  return (
    <Container
      data-testid="learning-object"
      maxWidth={false}
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid container>
        <Grid
          md={1}
          item
        >
          <Checkbox
            id={String(learningObjectDetail.learningObjectInstanceId)}
            checked={selected}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item md={11} display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            sx={{
              padding: '4px 0px',
            }}
            data-testid="learning-Grid-box"
          >
            <div className="course-detail-container">
              <Typography variant="subtitle1">
                {getType({ type: learningObjectDetail.learningObjectTypeId || 0 })}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{`${learningObjectDetail.learningObjectName} ${learningObjectDetail?.mustPass ? '(Required)' : '(Optional)'}`}</strong>
              </Typography>

              {ilt
                      && (
                      <>
                        <Typography variant="body2">
                          <strong>{'Instructor Name: '}</strong>
                          {learningObjectDetail?.instructorSummary}
                        </Typography>
                        <Box className="box-flex-row">
                          <Typography variant="body2">
                            <strong>{'Start Date: '}</strong>
                            {learningObjectDetail?.startDateTime ? formatDate(learningObjectDetail?.startDateTime) : ' - '}
                          </Typography>
                          <Typography variant="body2">
                            <strong>{'End Date: '}</strong>
                            {learningObjectDetail?.endDateTime ? formatDate(learningObjectDetail?.endDateTime) : ' - '}
                          </Typography>
                        </Box>
                      </>
                      )}
              <Typography variant="body2">
                <strong>{'Duration: '}</strong>
                {(online || ilt)
                  ? `${learningObjectDetail.duration || 0} hours`
                  : `${learningObjectDetail.numberOfDays || 0} days`}
              </Typography>
              <Typography variant="body2">
                <strong>{'Description: '}</strong>
                {learningObjectDetail.learningObjectDescription}
              </Typography>
              {enrolledStatus?.processSuccess && (
              <Typography color={(theme) => theme?.palette.success.main}>
                {enrolledStatus?.processDetailDescription}
              </Typography>
              )}
              {enrolledStatus?.enrollmentErrors && (
                React.Children.toArray(enrolledStatus?.enrollmentErrors.map((error: string) => (
                  <Typography color="error">
                    {error}
                  </Typography>
                ))))}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
