import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const useHandleApiError = (isError: boolean, error: FetchBaseQueryError) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isError || !error) return;

    let statusCode = null;
    let errorMessage = 'Unknown error';

    if (error && typeof error === 'object') {
      const baseQueryError = error as FetchBaseQueryError;
      statusCode = 'originalStatus' in baseQueryError ? baseQueryError.originalStatus : null;
      errorMessage = 'data' in baseQueryError ? (baseQueryError.data as string) : 'Unknown error';
    }

    if (!statusCode) return;

    let message = '';

    if (statusCode === 400) {
      message = `${errorMessage}, Please Try Again Later.`;
    } else if (statusCode === 404) {
      message = 'Service Not Available, Please Try Again Later.';
    } else if (statusCode === 503) {
      message = 'Service Not Available, Please Try Again Later.';
    } else if (statusCode === 500) {
      message = 'Service Not Available, Please Try Again Later.';
    } else {
      message = 'An unexpected error occurred. Please Try Again Later.';
    }

    enqueueSnackbar(message, { variant: 'error' });
  }, [isError, error, enqueueSnackbar]);
};

export default useHandleApiError;
