import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FormView, { IFormItem } from 'components/FormView';
import { formatDateTime } from 'utils/helpers';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT, COURSE_TYPES_ENUM } from 'utils/constants';
import ViewFormLayout from 'components/ViewFormLayout';
import {
  Alert, Button, Stack, Tooltip,
} from '@mui/material';
import { useEnrollCoursesMutation } from 'services/dataApi';
import { useSelector } from 'react-redux';
import { IAttachemnt, IState } from 'redux/interfaces';
import LoadingIcon from 'components/LoadingIcon';
import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { EnrollCoursesResponse } from 'services/interfaces';
import { LearningObject } from '../shared';

function ErrorCard({ data }: {data: EnrollCoursesResponse}) {
  if (data?.enrollmentErrors) {
    const error = data?.enrollmentErrors.join('; ');
    return <Alert severity="error">{error}</Alert>
  }
  return <Alert severity="error">An errolled process had an error, check it!</Alert>
}

interface ICourseDetails {
  courseDetail: LearningObject,
  goBack: Function,
}
export default function CourseDetails(
  { courseDetail, goBack }: ICourseDetails,
) {
  const [enrollSelfService, { isLoading: isLoadingEnroll }] = useEnrollCoursesMutation();
  const userId = useSelector((state: IState) => state.userProfile.userId);
  const [enrolledResponse, setEnrolledResponse] = useState<EnrollCoursesResponse[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const ilt = courseDetail?.learningObjectTypeId === COURSE_TYPES_ENUM.CLASSROOM;
  const online = courseDetail?.learningObjectTypeId === COURSE_TYPES_ENUM.ONLINE;

  const onEnrollSelf = async (_selected: LearningObject[]) => {
    if (!_selected.length) {
      return;
    }
    setEnrolledResponse([])
    const payloadList = _selected?.map((course : LearningObject) => ({
      userId,
      learningObjectInstanceId: String(course.learningObjectInstanceId),
      learningObjectId: String(course?.learningObjectId),
      attendees: String(userId),
      status: '2',
      isStartEnrollment: true,
      isProcessEnrollment: false,
    }))
    try {
      const response = await enrollSelfService(payloadList).unwrap();
      const course = response[0];

      setEnrolledResponse(response)

      if (course.enrollmentErrors?.length === 0) {
        enqueueSnackbar(`You have been enrolled successfully from ${courseDetail.learningObjectName} course`, { variant: 'success' });
        navigate('/dashboard');
      } else {
        enqueueSnackbar('Something went wrong trying to self enroll', { variant: 'error' })
      }
    } catch (error) {
      console.log({ error })
    }
  };

  const enrrolledError = enrolledResponse[0];

  const learningObjectForm: IFormItem[] = [
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.learningObjectName,
      fieldId: 'learningObjectName',
      label: 'Course Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'textArea',
      fieldValue: courseDetail?.learningObjectDescription,
      fieldId: 'learningObjectDescription',
      label: 'Description',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.learningObjectTypeName,
      fieldId: 'LearningObjectTypeName',
      label: 'Course Type',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.vendorName ?? '',
      fieldId: 'VendorName',
      label: 'Vendor Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: 'TBD',
      fieldId: 'paymentMode',
      label: 'Payment mode',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail.listPrice ? `$ ${courseDetail.listPrice}` : 'Free',
      fieldId: 'listPrice',
      label: 'List Price',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.promoPrice ?? 0,
      fieldId: 'promoPrice',
      label: 'Promo Price',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.creditUnits ?? 0,
      fieldId: 'creditUnits',
      label: 'Credit Units',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.creditHours ?? 0,
      fieldId: 'creditHours',
      label: 'Credit Hours',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: (online || ilt)
        ? `${courseDetail?.duration || 0} hours`
        : `${courseDetail?.numberOfDays || 0} days`,
      fieldId: 'duration',
      label: 'Duration',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'textArea',
      fieldValue: courseDetail?.publicDescription,
      fieldId: 'publicDescription',
      label: 'Notes',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail.fullFacilityName?.replaceAll(`${courseDetail.facilityName || ''} - `, '') || '',
      fieldId: 'fullFacilityName',
      label: 'Location',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.facilityDirection || ' - ',
      fieldId: 'facilityDirection',
      label: 'Direction',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: 'TBD',
      fieldId: 'acreditation',
      label: 'Acreditation',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'textArea',
      fieldValue: 'TBD',
      fieldId: 'outline',
      label: 'Outline',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.isSelectedOnGrid,
      fieldId: 'IsSelectedOnGrid',
      label: 'Is Selected On Grid',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.facilityRoomId,
      fieldId: 'facilityRoomId',
      label: 'Facility Room ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'checkbox',
      fieldValue: courseDetail?.advertisementOnly === 'True' ? 'true' : undefined,
      fieldId: 'AdvertisementOnly',
      label: 'Advertisement Only',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'checkbox',
      fieldValue: courseDetail?.includeWaitlist ? 'true' : undefined,
      fieldId: 'includeWaitlist',
      label: 'Include Waitlist',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'checkbox',
      fieldValue: courseDetail?.waitlistAutoTransfer ? 'true' : undefined,
      fieldId: 'waitlistAutoTransfer',
      label: 'Waitlist Auto Transfer',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.waitlistDays,
      fieldId: 'waitlistDays',
      label: 'Waitlist Days',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.studentCount,
      fieldId: 'studentCount',
      label: 'Student Count',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.instructorUserId,
      fieldId: 'instructorUserId',
      label: 'Instructor User ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.divisionSummary,
      fieldId: 'divisionSummary',
      label: 'Division Summary',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.learningObjectTypeResourceName,
      fieldId: 'learningObjectTypeResourceName',
      label: 'Learning Object Type Resource Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.learningObjectProcessStatusTypeName,
      fieldId: 'learningObjectProcessStatusTypeName',
      label: 'Learning Object Process Status Type Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.instructorSummary,
      fieldId: 'instructorSummary',
      label: 'Instructor Summary',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.fullFacilityName,
      fieldId: 'fullFacilityName',
      label: 'Full Facility Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.facilityAddress,
      fieldId: 'facilityAddress',
      label: 'Facility Address',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'checkbox',
      fieldValue: courseDetail?.forcePayment ? 'true' : undefined,
      fieldId: 'forcePayment',
      label: 'Force Payment',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.forceEvaluation,
      fieldId: 'forceEvaluation',
      label: 'Force Evaluation',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.forceROMS,
      fieldId: 'forceROMS',
      label: 'Force ROMS',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.webExSessionKey,
      fieldId: 'webExSessionKey',
      label: 'WebEx Session Key',
      titleSize: 3,
    },
  ];

  const learningObjectFormDates: IFormItem[] = [
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.timeZone,
      fieldId: 'timeZone',
      label: 'Time Zone',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: 'TBD',
      fieldId: 'DueDate',
      label: 'Due Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.startDatetime ? formatDateTime(courseDetail?.startDatetime) : '-',
      fieldId: 'startDatetime',
      label: 'Start Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.endDateTime ? formatDateTime(courseDetail?.endDateTime) : '-',
      fieldId: 'endDateTime',
      label: 'End Date',
      titleSize: 3,
    },
  ]

  const learningObjectFormInternalInfo: IFormItem[] = [
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.createdByUserId,
      fieldId: 'CreatedByuserId',
      label: 'Created By User ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.createdDate,
      fieldId: 'createdDate',
      label: 'Created Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.updatedByUserId,
      fieldId: 'UpdatedByuserId',
      label: 'Updated By User ID',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.updatedDate,
      fieldId: 'updatedDate',
      label: 'Updated Date',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.creatorFullName,
      fieldId: 'creatorFullName',
      label: 'Creator Full Name',
      titleSize: 3,
    },
    {
      editable: false,
      mandatory: true,
      type: 'input',
      fieldValue: courseDetail?.updatorFullName,
      fieldId: 'UpdatorFullName',
      label: 'Updator Full Name',
      titleSize: 3,
    },
  ]
  const attachmentColumns: TColumns[] = [
    {
      name: 'AttachmentID',
      column: 'Id',
      width: 100,
      sortable: true,
    },
    {
      name: 'AttachmentName',
      column: 'AttachmentName',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'AttachmentFileName',
      column: 'AttachmentFileName',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'AttachmentFileExt',
      column: 'AttachmentFileExt',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
    {
      name: 'CreatedDate',
      column: 'CreatedDate',
      width: 150,
      sortable: true,
      align: 'center',
      alignTitle: 'center',
    },
  ];

  const attachmentDataSet: TRows[] = (courseDetail?.attachments || []).map(
    (item: IAttachemnt) => ({
      ...item,
      id: item.AttachmentID,
      isCollapsable: false,
      details: [],
    }),
  );
  return (
    <ViewFormLayout
      testId="content-library-course-detail"
      pl={0}
      pr={0}
    >
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={() => goBack()}>{BUTTON_TEXT.BACK}</Button>
        </Tooltip>

        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.ENROLL}>
          <Button
            fullWidth={false}
            variant="contained"
            disabled={isLoadingEnroll}
            onClick={() => onEnrollSelf([courseDetail])}
          >
            {BUTTON_TEXT.ENROLL}
          </Button>
        </Tooltip>

      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Stack>
          {enrrolledError && !enrrolledError?.processSuccess && <ErrorCard data={enrrolledError} />}
          {enrrolledError?.processSuccess && (<Alert severity="success">{enrrolledError?.processDetailDescription}</Alert>)}
          {isLoadingEnroll
            ? <LoadingIcon /> : (
              <>
                <ViewFormLayout testId="content-library-course-detail">
                  <ViewFormLayout.Title> Course Information</ViewFormLayout.Title>
                  <ViewFormLayout.Body>
                    <FormView
                      style={{ paddingLeft: 1, paddingRight: 1 }}
                      fields={learningObjectForm}
                      onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
                    />
                  </ViewFormLayout.Body>
                </ViewFormLayout>
                <ViewFormLayout testId="content-library-course-detail-dates">
                  <ViewFormLayout.Title> Course Dates</ViewFormLayout.Title>
                  <ViewFormLayout.Body>
                    <FormView
                      style={{ paddingLeft: 1, paddingRight: 1 }}
                      fields={learningObjectFormDates}
                      onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
                    />
                  </ViewFormLayout.Body>
                </ViewFormLayout>
                <ViewFormLayout testId="content-library-course-detail-dates">
                  <ViewFormLayout.Title> Internal Information</ViewFormLayout.Title>
                  <ViewFormLayout.Body>
                    <FormView
                      style={{ paddingLeft: 1, paddingRight: 1 }}
                      fields={learningObjectFormInternalInfo}
                      onChangeValues={(obj: {id: string, value: any}) => console.log({ obj })}
                    />
                  </ViewFormLayout.Body>
                </ViewFormLayout>
                <ViewFormLayout testId="content-library-course-detail-attachment">
                  <ViewFormLayout.Title> Attachments</ViewFormLayout.Title>
                  <ViewFormLayout.Body>
                    <CustomTable
                      rows={attachmentDataSet}
                      columns={attachmentColumns}
                      collapseAllRow={false}
                      defaultSort="dueDate"
                    />

                  </ViewFormLayout.Body>
                </ViewFormLayout>
              </>
            )}
        </Stack>
      </ViewFormLayout.Body>
    </ViewFormLayout>
  )
}
