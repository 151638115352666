import React, { useEffect, useState } from 'react';

import CustomTable from 'components/CustomTable';
import { TColumns, TRows } from 'components/CustomTable/interface';
import LoadingBox from 'components/LoadingBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { ACTION_ITEM_TOOLTIP_TEXT, ACTIVE_STATUS } from 'utils/constants';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useGetUserManagementMutation } from 'services/dataApi';
import { UserManagementRequest, UserManagementResponse } from 'services/interfaces';
import { enqueueSnackbar } from 'notistack';

interface UserTableProps {
  handleNewUser: ()=>void;
  filters: UserManagementRequest;
}

function getActionButtons(handleNewUser: ()=>void) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_USER}>
        <IconButton onClick={handleNewUser} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_USER}>
        <IconButton onClick={handleNewUser} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default function UsersTable({ handleNewUser, filters }: UserTableProps) {
  const [dataSet, setDataSet] = useState<TRows[] | []>([]);
  const [getUserByFilter, { isLoading }] = useGetUserManagementMutation();

  function createData(items: UserManagementResponse[], handleNewUser: ()=>void): TRows[] {
    return items?.map((item: UserManagementResponse) => ({
      ...item,
      details: [],
      id: Number(item.userId),
      active: item.active ? ACTIVE_STATUS.YES : ACTIVE_STATUS.NO,
      isCollapsable: false,
      actions: getActionButtons(handleNewUser),
    }));
  }

  const fetchData = async (request: UserManagementRequest) => {
    try {
      const response = await getUserByFilter(request).unwrap();
      if (response.length > 0) {
        setDataSet(createData(response, handleNewUser))
      } else {
        setDataSet([]);
      }
    } catch (err) {
      enqueueSnackbar('Service Not Available, Please Try Again Later.', { variant: 'error' });
      setDataSet([]);
    }
  };

  useEffect(() => {
    const request: UserManagementRequest = filters;
    fetchData(request);
  }, [filters]);

  const columns: TColumns[] = [
    {
      name: 'Id',
      column: 'id',
      hidden: true,
      width: 200,
      sortable: true,
    },
    {
      name: 'User ID',
      column: 'userId',
      width: 200,
      sortable: true,
    },
    {
      name: 'First Name',
      column: 'firstName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Last Name',
      column: 'lastName',
      width: 120,
      sortable: true,
    },
    {
      name: 'User Name',
      column: 'userName',
      width: 120,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'employeeId',
      width: 120,
      sortable: true,
    },
    {
      name: 'Active',
      column: 'active',
      width: 120,
      sortable: true,
    },
    {
      name: 'Actions',
      column: 'actions',
      width: 120,
      sortable: false,
    },
  ];
  if (isLoading) {
    return <LoadingBox />
  }
  return (
    <CustomTable
      rows={dataSet}
      columns={columns}
      collapseAllRow={false}
      showSelectCheckbox={false}
    />

  )
}
