import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { IStepProps } from './interfaces';

type StepsRequired = {
  'pre-test-pop-up-view': boolean,
  'post-test-pop-up-view': boolean,
  'evaluation-pop-up-view': boolean
}

export default function StepperStyled(
  {
    steps, onChange, stepIndex = 0, stepsRequired,
  }: { onChange: Function, steps: IStepProps[], stepIndex: number, stepsRequired: StepsRequired},
) {
  const [activeStep, setActiveStep] = React.useState(stepIndex);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    onChange(activeStep)
  }, [activeStep]);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {React.Children.toArray(steps.map((step, index) => step?.show && (
          <Step>
            <StepLabel
              optional={
                index === ((steps?.length || 0) - 1) ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>
                {step.description}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {BUTTON_TEXT.BACK}
                    </Button>
                  </Tooltip>
                  <Tooltip title={
                    index === steps.length - 1
                      ? ACTION_ITEM_TOOLTIP_TEXT.FINISH
                      : ACTION_ITEM_TOOLTIP_TEXT.NEXT
                  }
                  >
                    <Button
                      variant="contained"
                      disabled={stepsRequired[step.key]}
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? BUTTON_TEXT.FINISH : BUTTON_TEXT.NEXT}
                    </Button>
                  </Tooltip>

                </div>
              </Box>
            </StepContent>
          </Step>
        )))}
      </Stepper>
      {(activeStep === steps.length) && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.RESET}>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              {BUTTON_TEXT.RESET}
            </Button>
          </Tooltip>

        </Paper>
      )}
    </Box>
  );
}
