/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge, BadgeProps, Button, Collapse, styled, Tooltip,
} from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { UserManagementRequest } from 'services/interfaces';
import UsersTable from './UsersTable/UsersTable';
import UserFilterForm from './UserFilterForm';

const INITIAL_FILTERS = {
  userId: null,
  firstName: null,
  lastName: null,
  middleName: null,
  userName: null,
  email: null,
  employeeId: null,
  division: null,
  active: null,
  jobCodeDescription: null,
  jobClassDescription: null,
  jobPositionDescription: null,
}
export default function UsersList() {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState<boolean>();
  const [filters, setFilters] = useState<UserManagementRequest>(INITIAL_FILTERS);

  const handleNewUser = () => {
    navigate('/admin/users/new');
  };

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const filtersApplyed = (filters: UserManagementRequest): number => Object.values(filters).filter((value) => value !== null && value !== 0).length;

  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.NEW_USER}>
          <Button variant="contained" onClick={handleNewUser}>
            {BUTTON_TEXT.NEW_USER}
          </Button>
        </Tooltip>

        <Button
          variant="contained"
          onClick={handleToggleFilters}
        >
          <StyledBadge badgeContent={filtersApplyed(filters)} color="secondary">
            {showFilters ? 'Hide Filters' : 'Filters'}
          </StyledBadge>
        </Button>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <Collapse in={showFilters}>
          <UserFilterForm
            onSearch={(data: UserManagementRequest) => {
              setFilters(data)
              handleToggleFilters()
            }}
            onReset={() => {
              setFilters(INITIAL_FILTERS);
              handleToggleFilters()
            }}
          />
        </Collapse>
        <UsersTable filters={filters} handleNewUser={handleNewUser} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
