/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React from 'react';

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement>,
    // eslint-disable-next-line no-unused-vars
    newPage: number
  ) => void;
}

export type TRows = {
  [key: string]: string | number | React.ReactNode,
  id: number,
  isCollapsable: boolean,
  details: any[],
  courseName?: string;
};

export type TColumns = {
  name: string,
  column: string,
  width?: number,
  height?: number,
  padding?: number,
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right',
  alignTitle?:'center' | 'inherit' | 'justify' | 'left' | 'right',
  sortable: boolean;
  hidden?: boolean;
  searchable?: boolean;
  filter?: boolean;
  filterLabel?: string;
};

export interface FilterProps {
  key: string, label: string
}
export interface LabelValues {
  isGroup?: boolean;
  key: string;
  label: string;
  values: string[];
}
export enum TableSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  UNSORTED = ''
}

export interface filterApplied{
  filterId: string,
  filterActionId?: string,
  filterIsGroup?: boolean,
  filterLabel: string,
  filterValue: string[],
  removeAction?: Function,
}
export interface ICustomPaginationActionsTable {
  rows: TRows[];
  columns: TColumns[];
  collapseAllRow?: boolean;
  defaultSort?: string;
  handleHidden?: Function;
  showSelectCheckbox?: boolean;
  showColumnsControl?: boolean;
  showSearchBar?: boolean;
  colSpanGroupTitle?: number;
  filterByParentGroup?: {
    key: string;
    label: string;
  };
  onHandleSelectCheckbox?: Function;
  actions?: React.ReactNode;
  externalFilters?: React.ReactNode;
  defaultFilters?: filterApplied[],
  onClearAllFilters?: Function;

}

export interface ITableHeader {
  column: TColumns;
  sort?: string;
  sortDirection: string;
  handleDescendingSort: Function
  handleAscendingSort: Function
}
