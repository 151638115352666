/* eslint-disable quotes */
/* eslint-disable no-unused-vars */

import { AnswerTypeDict } from "./interfaces";

// eslint-disable-next-line import/prefer-default-export
export type TOptions = {
    [key: string]: string,
  };

export const FILTERS_OPTIONS_COURSE_TYPE: TOptions = {
  ALL: 'All',
  CLASSROOM_INSTRUCTION_COURSE: 'Classroom Instruction Course',
  ONLINE_TRAINING_COURSE: 'On-Line Training Course',
  MANUAL_ENTRY: 'Manual Entry',
};

export const FILTERS_OPTIONS_COURSE_STATUS: TOptions = {
  ALL: 'All',
  PENDING: 'Pending',
  ENROLLED: 'Enrolled',
  CLASS_SESSION_COMPLETE: 'Class Session Complete',
  COMPLETED: 'Completed',
  CANCELLED_BY_USER: 'Cancelled By User',
  NO_SHOW: 'No Show',
  EVALUATION_COMPLETE: 'Evaluation Complete',
  ROMS_COMPLETED: 'ROMS Completed',
  SCHEDULED: 'Scheduled',
  CANCELLED_BY_AUTHORIZER: 'Cancelled By Authorizer',
  CANCELLED_BY_ADMIN: 'Cancelled By Admin',
  DENIED_BY_AUTHORIZER: 'Denied By Authorizer',
  DENIED_BY_ADMIN: 'Denied By Admin',
  COMPLETED_FAILED: 'Completed (Failed)',
  EMPLOYMENT_TERMINATED: 'Employment Terminated',
  WAITLIST: 'Waitlist',
  COURSE_EXPIRED: 'Course Expired',
  ECARD_ISSUED: 'ECard Issued',
};

export const COURSE_TYPE: TOptions = {
  'Classroom Instruction Course': 'ILT', // 1
  'On-Line Training Course': 'Online', // 2
  'Manual Entry': 'Manual', // 3
};

// eslint-disable-next-line no-shadow
export enum COURSE_TYPES_ENUM {
  MANUAL = 3,
  ONLINE = 2,
  CLASSROOM = 1,
  CERTIFICATION = -5,
  CERTIFICATION_1 = 11,
  ASSIGNMENT = 12,

}

export const COURSE_TYPE_ID: TOptions = {
  3: 'Manual',
  2: 'Online',
  1: 'ILT',
  '-5': 'Certification',
  11: 'Certification',
  12: 'Assignment',

};

export const SCORM_WBT_TYPE_ID = 6;

export const WBT_TYPE_TEST_ONLY = 3;

export const LAUNCH_COURSE_STEPS = {
  PRE_TEST_POP_UP_VIEW: 'pre-test-pop-up-view',
  LAUNCH_POP_UP_VIEW: 'launch-pop-up-view',
  POST_TEST_POP_UP_VIEW: 'post-test-pop-up-view',
  EVALUATION_POP_UP_VIEW: 'evaluation-pop-up-view',
  CHECKLIST_POP_UP_VIEW: 'checklist-pop-up-view',
  LAUNCH_POP_UP_SCORM_VIEW: 'launch-pop-up-scorm-view',
}

export const ACTION_ITEM_TOOLTIP_TEXT: TOptions = {
  ONLINE_TRAINING_COURSE: 'Launch Online Course',
  ATTACHMENT_FILE: 'Attachment File',
  NO_ATTACHMENT_FILE: 'This Course has no Attachments',
  COURSE_DETAILS: 'Course details',
  EVALUATION: 'Evaluation',
  MORE_INFO: 'More information',
  COURSE_ACTIONS: 'Launch Course',
  BACK: 'Go back to previous page',
  SAVE: 'Save changes',
  SAVING: 'Saving',
  EDIT_EVALUATION: 'Edit Evaluation',
  CLONE_EVALUATION: 'Clone Evaluation',
  PREVIEW_EVALUATION: 'Preview Evaluation',
  NEW_EVALUATION: 'Create New Evaluation',
  PRINT_EVALUATION: 'Print Evaluation',
  NEW_USER: 'Create New User',
  EDIT_USER: 'Edit User',
  VIEW_USER: 'View User',
  NEW_GROUP: 'Create New Group',
  VIEW_GROUP: 'View Group',
  EDIT_GROUP: 'Edit Group',
  PRINT_TRANSCRIPT: 'Print Transcript',
  PRINT_CHECK_LIST: 'Print Check List',
  UNENROLL_COURSE: 'Unenroll Course',
  NEW_RATING_TYPE: 'Create New Rating Type',
  EDIT_RATING_TYPE: 'Edit Rating Type',
  VIEW_RATING_TYPE: 'View Rating Type',
  HIDE_ALL: 'Hide All',
  SHOW_ALL: 'Show All',
  FINISH: 'Finish',
  NEXT: 'Next',
  RESET: 'Reset',
  NEW_COURSE: 'Create New Course',
  OK: 'Ok',
  LAUNCH_CHECKLIST: 'Please click here to launch the Checklist activity',
  LAUNCH_LEARNING_ACTIVITY: 'Please click here to launch the learning activity',
  LAUNCH_LEARNING_ACTIVITY_NEW_TAB: 'The course you are about to take will open a new tab of this browser',
  LAUNCH_LEARNING_ACTIVITY_NO_PREVIEW_SUPPORT: 'This file is not supported for live preview',
  LAUNCH_SCORM_ACTIVITY: 'Please click here to launch course activity',
  LAUNCH_EVALUATION_ACTIVITY: 'Please click here to launch the Evaluation activity',
  NEW_REPORT: 'Create New Report',
  SEARCH: 'Search',
  ENROLL_SELF: 'Enroll Self',
  ENROLL: 'Enroll',
  ENROLLING: 'Enrolling',
  AGREE: 'Agree',
  DESAGREE: 'Desagree',
  GO_LOGIN: 'Go to login',
  CLONE: 'Clone',
  PREVIEW: 'Preview',
  NEW_SCORM_COURSE: 'Create New SCORM Course',
  EDIT_EVALUATION_QUESTION_GROUP: 'Edit Evaluation Question Group',
  EDIT_SCORM_COURSE: 'Edit SCORM Course',
  EDIT_COURSE: 'Edit Course',
  VIEW_CLASS_USERS: 'View Class Users',
  DOWNLOAD_ATTACHMENT_FILE: 'Download attachment file',
  SUBMIT_POST_TEST: "Please click here to submit the Post Test activity",
  SUBMIT_PRE_TEST: "Please click here to submit the Pre Test activity",
};

export const BUTTON_TEXT: TOptions = {
  BACK: 'Back',
  SAVE: 'Save',
  SAVING: 'Saving',
  CANCEL: 'Cancel',
  PREVIEW: 'Preview',
  CLONE: 'Clone',
  EDIT: 'Edit',
  NEW: 'New',
  NEW_USER: 'New User',
  PRINT_TRANSCRIPT: 'Print Transcript',
  PRINT_CHECK_LIST: 'Print Check List',
  UNENROLL: 'Unenroll',
  NEW_RATING_TYPE: 'New Rating Type',
  NEW_GROUP: 'New Group',
  HIDE_ALL: 'Hide All',
  SHOW_ALL: 'Show All',
  FINISH: 'Finish',
  NEXT: 'Next',
  RESET: 'Reset',
  NEW_EVALUATION: 'New Evaluation',
  PRINT_EVALUATION: 'Print Evaluation',
  NEW_COURSE: 'New Course',
  OK: 'Ok',
  LAUNCH: 'Launch',
  LAUNCH_CHECKLIST: 'Please click here to launch the Checklist activity',
  LAUNCH_LEARNING_ACTIVITY: 'Please click here to launch the learning activity',
  LAUNCH_SCORM_ACTIVITY: 'Please click here to launch course activity',
  LAUNCH_EVALUATION_ACTIVITY: 'Please click here to launch the Evaluation activity',
  NEW_REPORT: 'New Report',
  SEARCH: 'Search',
  ENROLL_SELF: 'Enroll Self',
  ENROLL: 'Enroll',
  ENROLLING: 'Enrolling',
  AGREE: 'Agree',
  DESAGREE: 'Desagree',
  GO_LOGIN: 'Go to login',
  NEW_SCORM_COURSE: 'New SCORM Course',
  NEW_EVALUATION_QUESTION_GROUP: 'New Evaluation Question Group',
  EDIT_SCORM_COURSE: 'Edit SCORM Course',
  DOWNLOAD_COURSE: 'Download Course',
  FIND_COURSES: 'Find Courses',
  SUBMIT: 'Submit',
}

export const PROFILE_TEXT: TOptions = {
  ABOUT_ME: "About me",
  MY_GROUPS: "My Groups",
  ACCOUNT_STATUS: 'Account Status',
  PERSONAL_INFORMATION: 'Personal Information',
  EMPLOYEE_INFORMATION: 'Employee Information',
  CLASSIFICATIONS: 'Classifications',
  AVAILABLE_DEVELOPMENT_PLAN: 'Available Development Plan',
  COURSE_LAUNCHER: 'Course Launcher',
  NO_CONTENT_AVAILABLE: 'No Content Available.',
  SCORM_NO_SUPPORTED: 'This SCORM course is not supported in NextGen , please use Legacy Application or Contact your Administrator.!',
  SCORM_GENERATED_FAIL: 'Failing in the SCORM instance generation!',
}

export const ACTIVE_STATUS = {
  YES: 'Yes',
  NO: 'No',
}

export const MAP_FILTERS
: TOptions = {
  All: 'All',
  Classroom: 'ilt',
  Online: 'Online',
  Certification: 'Certification',
}

export const STATUS_OPTION_LABELS: TOptions = {
  SUCCESS: 'You are up to date!',
  WARNING: 'Courses, Certifications, Assignments due soon!',
  ERROR: 'Some items need your attention!!',
};

export const CHECK_TOKEN_EVERY_TIME = 600000;

export const DEFAULT_APP_THEME_PRIMARY_COLOR = '#085AC9';

export enum FILTER_KEY {
  RemoveDate = 'remove-date',
  RemoveStatus = 'remove-status',
  RemoveAll = 'all',
}

export type COURSE_STATUS_BY_DATE = "Overdue" | "Upcoming" | "Future" | "Flexible" | "Due soon";
export enum COURSE_STATUS_BY_DATE_COLORS {
  Overdue = '#cf171a',
  Duesoon = '#ff7f00',
  Upcoming = '#ffcc00',
  Future = '#377eb8',
  Flexible = '#bdbdbd'
}
export enum COURSE_STATUS_BY_DATE_LABEL {
  Overdue = "Overdue",
  Duesoon = "Due soon",
  Upcoming = "Upcoming",
  Future = "Future",
  Flexible = "Flexible"
}

export const COURSE_STATUS_TOOLTIP_TEXT: TOptions = {
  OVERDUE: 'Due Date is in the past',
  DUESOON: 'Due Date is in the next 7 days',
  UPCOMING: 'Due Date is in the next 30 days',
  FUTURE: 'Due Date is greater than 30 days',
  FLEXIBLE: 'There is no Due Date associated',
};

export const ADMIN_USER_IDS = [1, 3, 4, 12];

export enum STATUS_LABEL {
  Overdue = 'Overdue',
  InProgress = 'In Progress',
  NotStarted = 'Not Started',
  Undefined = 'Undefined'
}

export const STRING_FORMAT_DAY = 'YYYY/MM/DD';

export enum EvaluationProgressStatus {
  Assigned = 'Assigned',
  InProgress = 'In Progress',
  ApproverAssigned = 'Approver Assigned',
  ApproverSignedOff = 'Approver Signed off',
  EmployeeSignOffRequired = 'Employee Sign off Required',
  EmployeeSignedOff = 'Employee Signed Off',
  ManageCompletes = 'Manager Completes'
}

export const HTTP_SERVER_ERROR_CODES = [500];
export const HTTP_CLIENT_ERROR_CODES = [400, 404];
export const NAVIGATION_URL = {
  ADMIN_SCORM: 'scorm-courses',
  ADMIN_SCORM_NEW: 'scorm-courses/new',
  ADMIN_SCORM_DETAIL: 'scorm-courses/detail',
};

export const RESOURCE_PATTERNS = {
  vimeoPattern: /https?:\/\/(www\.)?vimeo\.com\/(\d+)/,
  youtubePattern: /https?:\/\/(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+/,
  mp4Pattern: /https?:\/\/.*\.mp4$/,
  mpeg4Pattern: /https?:\/\/.*\.mpeg4$/,
  pdfPattern: /https?:\/\/.*\.pdf$/,
  pptPattern: /https?:\/\/.*\.ppt$/,
}

export const AnswerTypesId: AnswerTypeDict = {
  RadioButton: 1,
  TextBox: 2,
  TextArea: 3,
  CheckBox: 4,
}

export const PAST_COURSES = [
  'Completed',
  'Cancelled By User',
  'No Show',
  'Cancelled By Authorizer',
  'Cancelled By Admin',
  'Denied By Authorizer',
  'Denied By Admin',
  'Completed (Failed)',
  'Employment Terminated',
  'Course Expired',
  'ECard Issued',
];

export const YELLLOWFIN_COOKIE_NAME = process.env.REACT_APP_REPORTING_TOOL_COOKIE_NAME;
export const YELLLOWFIN_COOKIE_DOMAIN = process.env.REACT_APP_REPORTING_TOOL_COOKIE_DOMAIN;
export const { REACT_APP_COURSE_ENGINE_URL } = process.env;
